<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="man-title">
        <span>水表台账</span>
        <div class="btn">
          <!-- <el-button style="margin:3px 15px 0;" @click="dlt()" type="primary" size="mini">删除清单</el-button> -->
          <el-upload 
            action=""
            :auto-upload="false"
            accept=".xlsx, .xls"
            :show-file-list="false"
            :on-change="handleChange">
            <el-button type="primary" size="mini">导入台账</el-button>
          </el-upload>
        </div>
      </div>
      <div class="search">
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
          <el-form-item label="用户编号">
            <el-input type="text" size="small" v-model="formInline.userCode" clearable placeholder="请输入用户编号"></el-input>
          </el-form-item>
          <el-form-item label="用户名称">
            <el-input type="text" size="small" v-model="formInline.userName" clearable placeholder="请输入用户名称"></el-input>
          </el-form-item>
          <el-form-item label="证件号码">
            <el-input type="text" size="small" v-model="formInline.idNumber" clearable placeholder="请输入证件号码"></el-input>
          </el-form-item>
          <el-form-item label="所属区域">
            <el-select size="small" v-model="formInline.regionId" clearable placeholder="请选择所属区域">
              <el-option
                v-for="item in regionList"
                :label="item.name"
                :value="item.id"
                :key="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属册本">
            <el-select size="small" v-model="formInline.zoneId" clearable placeholder="请选择所属册本">
              <el-option
                v-for="item in zoneList"
                :label="item.name"
                :value="item.id"
                :key="item.id"></el-option>
            </el-select>
          </el-form-item>
         
          <el-form-item label="用户状态">
            <el-select size="small" v-model="formInline.userStatus" clearable placeholder="请选择用户状态">
              <el-option
                v-for="item in userStatusList"
                :label="item.name"
                :value="item.id"
                :key="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button size="small" type="primary" @click="onSubmit">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="table-list">
        <el-table :data="tableData" style="width: 100%" class="tables">
          <el-table-column fixed prop="userCode" label="用户编号" width="150"></el-table-column>
          <el-table-column prop="waterMeterCode" label="水表编号" width="150"></el-table-column>
          <el-table-column prop="userName" label="用户名称" width="150"></el-table-column>
          <el-table-column prop="address" label="水表地址" width="300"></el-table-column>
          <el-table-column prop="useProperty" label="用水性质" width="150"></el-table-column>
          <el-table-column prop="originPlace" label="水表产地" width="150"></el-table-column>
          <el-table-column prop="type" label="水表类型" width="100"></el-table-column>
          <el-table-column prop="caliber" label="水表口径" width="100"></el-table-column>
          <el-table-column prop="model" label="水表型号" width="100"></el-table-column>
          <el-table-column prop="valve" label="阀门状态" width="100"></el-table-column>
          <el-table-column prop="readType" label="抄表方式" width="150"></el-table-column>
          <el-table-column prop="capacity" label="水表容量" width="100"></el-table-column>
          <el-table-column prop="watchCase" label="表壳号" width="100"></el-table-column>
          <el-table-column prop="meterCore" label="表芯号" width="100"></el-table-column>
          <el-table-column prop="runningStatus" label="水表状态" width="100"></el-table-column>
          <el-table-column prop="position" label="安装位置" width="100"></el-table-column>
          <el-table-column prop="createTime" label="安装时间" width="180">
            <template slot-scope="scope">
              {{ scope.row.createTime ? dayjs(scope.row.createTime).format('YYYY-MM-DD HH:mm:ss'):'' }}
            </template>
          </el-table-column>
          <el-table-column fixed="right" prop="" label="操作" width="180">
            <template slot-scope="scope">
              <el-popover
                placement="left"
                :title="'水表编号：'+scope.row.waterMeterCode"
                width="200"
                trigger="click">
                <el-image
                  :src="qrcodeUrl"
                  :preview-src-list="[qrcodeUrl]">
                </el-image>
                <el-button type="primary" size="small" slot="reference" @click="queryCode(scope.row)">查看二维码</el-button>
              </el-popover>
            </template>
          </el-table-column>
        </el-table>
        <page v-if="total!==0" :size="size" :page="page" :total="total" v-on:sizeChange="sizeChange" v-on:pageChange="pageChange"></page>
      </div>
    </div>
  </div>
</template>

<script>
import page from '@/components/page.vue'
import dayjs from 'dayjs';
export default {
  components: {
    page
  },
  data() {
    return {
      loading: false,
      size: 50,
      page: 1,
      total: 0,
      formInline: {},
      tableData: [],
      regionList: [],
      zoneList: [],
      userStatusList: [
        {id:'working',name:'在户'},
        {id:'stopping',name:'停户'},
        {id:'written',name:'注销'}
      ],
      staffId: 0,
      roleId: 0,
      qrcodeUrl: ''
    }
  },
  methods: {
    dayjs,
    handleChange(e) {
      console.log(e)
      let formData = new FormData();
      formData.append("excelFile", e.raw);
      
      this.loading = true
      this.$ajax.post("meterExcel", formData).then((res) => {
        this.loading = false
        this.$message.success("导入成功");
        this.loadTableData()
      }).catch(err=>{
        this.loading = false
      })
    },
    onOff(row) {
      this.$ajax.post("ruleStatus", {
        id: row.id,
        status: row.status == 0 ? 1 : 0
      }).then((res) => {
        this.loadTableData();
      });
    },
    onSubmit() {
      this.page = 1
      sessionStorage.setItem('currentPage', 1)
      this.loadTableData()
    },
    details(row) {
    //   this.$router.push({ name: 'faultReportDetail', params: {row: row}})
    },
    pageChange(val) {
      this.page = val
      sessionStorage.setItem('currentPage', val)
      this.loadTableData()
    },
    sizeChange(val) {
      this.size = val
      sessionStorage.setItem('currentSize', val)
      this.loadTableData()
    },
    getLastPage() {
      this.page = Number(sessionStorage.getItem('currentPage')) || 1
    },
    getSize() {
      this.size = Number(sessionStorage.getItem('currentSize')) || 50
    },
    loadTableData() {
      this.loading = true
      this.$ajax.post('meterQuery', {
        pageNum: this.page,
        pageSize: this.size,
        userCode: this.formInline.userCode?this.formInline.userCode:null,
        userName: this.formInline.userName?this.formInline.userName:null,
        idNumber: this.formInline.idNumber?this.formInline.idNumber:null,
        regionId: this.formInline.regionId?this.formInline.regionId:null,
        zoneId: this.formInline.zoneId?this.formInline.zoneId:null,
        userStatus: this.formInline.userStatus?this.formInline.userStatus:null
      }).then(res => {
        this.tableData = res.data
        this.total = res.total
        this.loading = false
      }).catch(err=>{
        this.loading = false
      })
    },
    loadRegionList() {
      this.$ajax.post("regionQuery").then((res) => {
        this.regionList = res.data;
      });
    },
    loadZoneList() {
      this.$ajax.post("zoneQuery",{
        pageNum: 0,
        pageSize: 0,
      }).then((res) => {
        this.zoneList = res.data;
      });
    },
    // 查询水表二维码
    qrcodeQuery(waterMeterCode) {
      this.$ajax.post("qrcodeQuery",{}, {
        waterMeterCode: waterMeterCode,
      }).then((res) => {
        this.qrcodeUrl = res.data
      })
    },
    // 查看二维码
    queryCode(row) {
      this.qrcodeQuery(row.waterMeterCode)
    }
  },
  mounted() {
    this.getLastPage()
    this.getSize()
    this.loadTableData()
    this.loadRegionList()
    this.loadZoneList()
    this.staffId = sessionStorage.getItem('id')
    this.roleId = sessionStorage.getItem('r')
  },
  destroyed() {
    sessionStorage.removeItem('currentPage')
    sessionStorage.removeItem('currentSize')
  }
}
</script>

<style lang="less" scoped>
.el-dropdown-dis {
  color: #ccc;
  cursor: not-allowed;
}
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.btn {
  display: flex;
  align-items: center;
  float: right;
}
.search {
  padding: 0 10px;
}
.tables {
  ::v-deep .el-table__fixed {
    height: 100% !important;
  }
}
</style>
<style>
.el-popover__title {
  text-align: center;
  font-size: 14px;
  margin: 10px 0 0 0;
}
</style>